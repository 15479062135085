.Text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 250px;
    margin: 10;
}

@mixin btn-border-drawing($color: #ccc, $hover: black, $width: 2px, $vertical: top, $horizontal: left, $duration: 0.25s) {
  box-shadow: inset 0 0 0 $width $color;
  color: $color;
  transition: color $duration $duration/3;
  position: relative;
  
  &::before,
  &::after {
    border: 0 solid transparent;
    box-sizing: border-box;
    content: '';
    pointer-events: none;
    position: absolute;
    width: 0; height: 0;
    
    #{$vertical}: 0; 
    #{$horizontal}: 0;
  }

  &::before {
    $h-side: if($horizontal == 'left', 'right', 'left');
    
    border-#{$vertical}-width: $width;
    border-#{$h-side}-width: $width;
  }
  
  &::after {
    $v-side: if($vertical == 'top', 'bottom', 'top');
    
    border-#{$v-side}-width: $width;
    border-#{$horizontal}-width: $width;
  }
  
  &:hover {
    color: $hover;
    
    &::before,
    &::after {
      border-color: $hover;
      transition: border-color 0s, width $duration, height $duration;
      width: 100%;
      height: 100%;
    }
    
    &::before { transition-delay: 0s, 0s, $duration; }
    
    &::after { transition-delay: 0s, $duration, 0s; }
  }
}

.draw-border {
  @include btn-border-drawing(#58afd1, #ffe593, 4px, bottom, right);
}

//=== Button styling, semi-ignore
.btn {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font: 700 1.2rem 'Roboto Slab', sans-serif;
  padding: 1em 2em;
  letter-spacing: 0.05rem;
  margin: 25px 25px 10px 10px;
  
  &:focus { outline: 2px dotted #55d7dc; }
}

//=== Pen styling, ignore
body { 
  background: #1f1a25;
  display: flex; 
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}